import { DocumentReference, DocumentSnapshot } from "firebase/firestore";
import MotoristaDb from "../db/motoristaDb";

export default class Motorista implements IMotorista {
  public ref!: DocumentReference<Motorista>;
  public nome!: string;
  public email!: string;
  public matricula!: string;

  static fromFirestore(snapshot: DocumentSnapshot): Motorista {
    return new Motorista({
      ref: snapshot.ref.withConverter(new MotoristaDb().getConverter()),
      nome: snapshot.get("nome"),
      email: snapshot.get("email"),
      matricula: snapshot.get("matricula"),
    });
  }

  constructor(param: IMotorista) {
    Object.assign(this, param);
  }
}

interface IMotorista {
  ref: DocumentReference<Motorista>;
  nome: string;
  email: string;
  matricula: string;
}
