import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  addDoc,
  deleteDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { map } from "lodash";
import { useEffect, useState } from "react";
import ResidencialDb from "../db/residencialDb";
import Residencial from "../models/residencial";
import avatarColorGenerator from "../util/avatarColorGenerator";
import { Add, Delete, Edit } from "@mui/icons-material";

export default function PaginaResidenciais() {
  const [residenciais, setResidenciais] = useState<Residencial[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return onSnapshot(
      query(
        new ResidencialDb().collection({
          residencialId: "",
        })
      ),
      (queryDocs) => {
        setResidenciais(map(queryDocs.docs, (doc) => doc.data()));
      }
    );
  }, []);

  return (
    <Container maxWidth="lg">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(residenciais, (residencial) => {
              return (
                <RowResidencial
                  key={residencial.ref.id}
                  residencial={residencial}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        variant="extended"
        size="large"
        color="success"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => setOpen(true)}
      >
        <Add />
        Adicionar
      </Fab>
      <DialogResidencial open={open} onClose={() => setOpen(false)} />
    </Container>
  );
}

function RowResidencial({ residencial }: { residencial: Residencial }) {
  const [open, setOpen] = useState(false);
  return (
    <TableRow key={residencial.ref.id}>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{ backgroundColor: avatarColorGenerator(residencial.ref.id) }}
          >
            {residencial.nome[0]}
          </Avatar>
          <Box mr={1} />
          {residencial.nome}
        </Box>
      </TableCell>
      <TableCell align="right">
        <DialogResidencial
          residencial={residencial}
          open={open}
          onClose={() => setOpen(false)}
        />
        <IconButton color="info" onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
        <IconButton color="error" onClick={() => deleteDoc(residencial.ref)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function DialogResidencial({
  open,
  onClose,
  residencial,
}: {
  open: boolean;
  onClose: () => void;
  residencial?: Residencial;
}) {
  const [nome, setNome] = useState(residencial?.nome ?? "");
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <FormGroup>
          <Box display="flex" flexDirection="column">
            <TextField
              fullWidth
              label="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </Box>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="text" color={"inherit"} onClick={onClose}>
          {"Cancelar"}
        </Button>
        {!!residencial ? (
          <Button
            size="large"
            variant="contained"
            color={"success"}
            disabled={!!!nome}
            onClick={() =>
              setDoc(
                residencial.ref,
                new Residencial({
                  ...residencial,
                  nome,
                })
              ).then(onClose)
            }
          >
            {"Salvar"}
          </Button>
        ) : (
          <Button
            size="large"
            variant="contained"
            color={"success"}
            disabled={!!!nome}
            onClick={() =>
              addDoc(
                new ResidencialDb().collection({ residencialId: "" }),
                new Residencial({
                  ref: new ResidencialDb().doc({ residencialId: "-" }),
                  nome,
                })
              ).then(onClose)
            }
          >
            {"Adicionar"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
