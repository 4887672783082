import { FC, PropsWithChildren } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Auth from "./components/auth";
import { AuthProvider, useAuth } from "./components/auth/authProvider";
import Layout from "./layout";
import Home from "./pages/home";
import PaginaLinhas from "./pages/linhas";
import PaginaPassageiros from "./pages/passageiros";
import PaginaResidenciais from "./pages/residenciais";
import PaginaCarros from "./pages/carros";
import PaginaCheckins from "./pages/checkins";
import PaginaHorarios from "./pages/horarios";
import PaginaTermo from "./pages/termo";
import PaginaTermos from "./pages/termos";
import PaginaAdministradores from "./pages/administradores";
import { map } from "lodash";
import PaginaMotoristas from "./pages/motoristas";
import PaginaCorridas from "./pages/corridas";
import PaginaCorrida from "./pages/corrida";
import PaginaAlertas from "./pages/alertas";

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route key="" path="/" element={<Home />} />
          <Route key="termo" path="termo" element={<PaginaTermo />} />
          <Route key="login" path="login" element={<Auth />} />
          <Route key="app" path="app" element={<Layout />}>
            {map(AppRoutes, (appRoute) => (
              <Route
                key={appRoute.path}
                path={appRoute.path}
                element={<PrivateRoute>{appRoute.element}</PrivateRoute>}
              />
            ))}
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export const AppRoutes = [
  {
    path: "linhas",
    titulo: "Linhas",
    element: <PaginaLinhas />,
    drawer: true,
  },
  {
    path: "horarios",
    titulo: "Horários",
    element: <PaginaHorarios />,
    drawer: true,
  },
  {
    path: "carros",
    titulo: "Carros",
    drawer: true,
    element: <PaginaCarros />,
  },
  {
    path: "residenciais",
    titulo: "Residenciais",
    element: <PaginaResidenciais />,
    drawer: true,
  },
  {
    path: "passageiros",
    titulo: "Passageiros",
    element: <PaginaPassageiros />,
    drawer: true,
  },
  {
    path: "motoristas",
    titulo: "Motoristas",
    element: <PaginaMotoristas />,
    drawer: true,
  },
  {
    path: "administradores",
    titulo: "Administradores",
    element: <PaginaAdministradores />,
    drawer: true,
  },
  {
    path: "corridas",
    titulo: "Corridas",
    element: <PaginaCorridas />,
    drawer: true,
  },
  {
    path: "corridas/:corridaId",
    titulo: "Corrida",
    element: <PaginaCorrida />,
    drawer: false,
  },
  {
    path: "alertas",
    titulo: "Alertas",
    element: <PaginaAlertas />,
    drawer: true,
  },
  {
    path: "checkins",
    titulo: "Check-ins",
    element: <PaginaCheckins />,
    drawer: true,
  },
  {
    path: "termos",
    titulo: "Termos",
    element: <PaginaTermos />,
    drawer: true,
  },
];

const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();
  if (auth.loading) return <></>;
  if (auth.isAuthenticated) return <>{children}</>;
  return <Navigate to="/login" />;
};
