import { FirestoreDataConverter } from "firebase/firestore";
import toFirestore from "../models/toFirestore";
import Passageiro from "../models/passageiro";
import BaseDb from "./baseDb";

export default class PassageiroDb extends BaseDb<
  Passageiro,
  {
    passageiroId: string;
  }
> {
  _path: string = "passageiros/passageiroId";
  getConverter(): FirestoreDataConverter<Passageiro> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Passageiro.fromFirestore,
    };
  }
}
