import { Check, DeleteForever, Edit } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  PontoState,
  PontosActionType,
  usePontosDispatch,
} from "../../reducers/pontosReducerProvider";

type Props = {
  ponto: PontoState;
};
export default function ItemPonto({ ponto }: Props) {
  const pontosDispatch = usePontosDispatch();
  const [nome, setNome] = useState(ponto.nome);

  useEffect(() => {
    setNome(ponto.nome);
  }, [ponto.nome]);

  return (
    <>
      <ListItem
        key={ponto.nome}
        dense
        onMouseEnter={() =>
          pontosDispatch({
            type: PontosActionType.EDITAR,
            payload: {
              editar: {
                ...ponto,
                destaque: true,
              },
            },
          })
        }
        onMouseLeave={() =>
          pontosDispatch({
            type: PontosActionType.EDITAR,
            payload: {
              editar: {
                ...ponto,
                destaque: false,
              },
            },
          })
        }
      >
        <ListItemText primary={ponto.nome} />
        <IconButton
          onClick={() => {
            if (ponto.selecionado)
              pontosDispatch({
                type: PontosActionType.EDITAR,
                payload: {
                  editar: {
                    ...ponto,
                    nome,
                    selecionado: false,
                  },
                },
              });
            else
              pontosDispatch({
                type: PontosActionType.EDITAR,
                payload: {
                  editar: { ...ponto, selecionado: true },
                },
              });
          }}
          color={ponto.selecionado ? "success" : "info"}
        >
          {ponto.selecionado ? <Check /> : <Edit />}
        </IconButton>
        <IconButton
          edge="end"
          color="error"
          onClick={() => {
            pontosDispatch({
              type: PontosActionType.REMOVER,
              payload: {
                remover: ponto,
              },
            });
            // setCalcularRota(true);
          }}
        >
          <DeleteForever />
        </IconButton>
      </ListItem>
      <Collapse in={ponto.selecionado}>
        <Box p={2}>
          <TextField
            fullWidth
            label="Nome"
            size="small"
            variant="outlined"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <Typography variant="caption">{`lat: ${ponto.localizacao.latitude.toFixed(
            2
          )}, lng: ${ponto.localizacao.longitude.toFixed(2)}`}</Typography>
        </Box>
        <Divider />
      </Collapse>
    </>
  );
}
