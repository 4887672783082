import { AddLocationAlt } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { GeoPoint } from "firebase/firestore";
import { filter, first, flatMap, map, max, min } from "lodash";
import { useState } from "react";
import {
  PontosActionType,
  usePontos,
  usePontosDispatch,
} from "../../reducers/pontosReducerProvider";

export default function ItemNovoPonto() {
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [places, setPlaces] = useState<
    google.maps.places.PlaceResult[] | undefined
  >();
  const { pontos } = usePontos();
  const pontosDispatch = usePontosDispatch();

  const onPlacesChanged = () => {
    if (!!searchBox) {
      const places = searchBox.getPlaces();
      setPlaces(places);
    }
  };

  return (
    <>
      <Box p={2}>
        <Box
          display="flex"
          flexGrow={1}
          data-standalone-searchbox=""
          sx={{ " div": { width: "100%" }, mr: 1 }}
        >
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={(sb) => setSearchBox(sb)}
            bounds={{
              north: max(map(pontos, (p) => p.localizacao.latitude)) ?? 5 + 0.3,
              south:
                min(map(pontos, (p) => p.localizacao.latitude)) ?? -30 - 0.3,
              east:
                max(map(pontos, (p) => p.localizacao.longitude)) ?? -34 + 0.3,
              west:
                min(map(pontos, (p) => p.localizacao.longitude)) ?? -73 - 0.3,
            }}
          >
            <TextField
              fullWidth
              placeholder="Procure um ponto para adicionar"
              size="small"
              variant="outlined"
            />
          </StandaloneSearchBox>
        </Box>
        <List>
          {map(
            filter(
              places,
              (p) => !!p.place_id && !!p.name && !!p.geometry?.location
            ),
            (place) => (
              <ListItem key={place.place_id}>
                <ListItemText>{place.name}</ListItemText>
                <IconButton
                  color="secondary"
                  onClick={() => {
                    pontosDispatch({
                      type: PontosActionType.ADICIONAR,
                      payload: {
                        adicionar: {
                          id: place.place_id!,
                          nome: place.name!,
                          foto: first(place.photos)?.getUrl() || "",
                          localizacao: new GeoPoint(
                            place.geometry!.location!.lat(),
                            place.geometry!.location!.lng()
                          ),
                          destaque: false,
                          selecionado: false,
                        },
                      },
                    });
                    setPlaces([]);
                  }}
                >
                  <AddLocationAlt />
                </IconButton>
              </ListItem>
            )
          )}
        </List>
        <ImageList>
          {map(
            flatMap(places, (place) => place.photos),
            (i) =>
              !!i ? (
                <ImageListItem>
                  <img
                    src={`${i?.getUrl()}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${i?.getUrl()}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={i?.getUrl()}
                    loading="lazy"
                  />
                </ImageListItem>
              ) : (
                <></>
              )
          )}
        </ImageList>
      </Box>
    </>
  );
}
