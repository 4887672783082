import { DocumentReference, DocumentSnapshot } from "firebase/firestore";
import CarroDb from "../db/carroDb";

export default class Carro implements ICarro {
  public ref!: DocumentReference<Carro>;
  public numero!: string;
  public placa!: string;
  public microOnibus!: boolean;

  constructor(param: ICarro) {
    Object.assign(this, param);
  }

  static fromFirestore(snapshot: DocumentSnapshot): Carro {
    return new Carro({
      ref: snapshot.ref.withConverter(new CarroDb().getConverter()),
      numero: snapshot.get("numero"),
      placa: snapshot.get("placa"),
      microOnibus: snapshot.get("microOnibus"),
    });
  }
}

interface ICarro {
  ref: DocumentReference<Carro>;
  numero: string;
  placa: string;
  microOnibus: boolean;
}
