import { FirestoreDataConverter } from "firebase/firestore";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";
import Horario from "../models/horario";

export default class HorarioDb extends BaseDb<
  Horario,
  {
    horarioId: string;
  }
> {
  _path: string = "horarios/horarioId";
  getConverter(): FirestoreDataConverter<Horario> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Horario.fromFirestore,
    };
  }
}
