import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Termo from "../models/termo";
import { limit, onSnapshot, orderBy, query } from "firebase/firestore";
import TermoDb from "../db/termoDb";

export default function PaginaTermo() {
  const [termo, setTermo] = useState<Termo>();
  useEffect(() => {
    return onSnapshot(
      query(
        new TermoDb().collection({ termoId: "" }),
        orderBy("data", "desc"),
        limit(1)
      ),
      (snapshot) => setTermo(snapshot.docs[0].data())
    );
  }, []);
  return (
    <Container maxWidth="lg">
      {!!termo ? (
        <>
          <Box dangerouslySetInnerHTML={{ __html: termo?.texto ?? "" }}></Box>
          <Typography>
            Atualizado em {termo.data.toLocaleString("pt-BR")}
          </Typography>
        </>
      ) : (
        <CircularProgress />
      )}
    </Container>
  );
}
