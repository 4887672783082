import { FirestoreDataConverter } from "firebase/firestore";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";
import Localizacao from "../models/localizacao";

export default class LocalizacaoDb extends BaseDb<
  Localizacao,
  {
    corridaId: string;
    localizacaoId: string;
  }
> {
  _path: string = "corridas/corridaId/localizacao/localizacaoId";
  getConverter(): FirestoreDataConverter<Localizacao> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Localizacao.fromFirestore,
    };
  }
}
