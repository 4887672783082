import { DocumentReference, DocumentSnapshot } from "firebase/firestore";
import Linha from "./linha";
import HorarioDb from "../db/horarioDb";
import LinhaDb from "../db/linhaDb";

export default class Horario implements IHorario {
  public ref!: DocumentReference<Horario>;
  public linha!: DocumentReference<Linha>;
  public ativo!: boolean;
  public dias!: DIAS;
  public horarios!: string[];

  constructor(param: IHorario) {
    Object.assign(this, param);
  }

  static fromFirestore(snapshot: DocumentSnapshot) {
    return new Horario({
      ref: snapshot.ref.withConverter(new HorarioDb().getConverter()),
      linha: (snapshot.get("linha") as DocumentReference).withConverter(
        new LinhaDb().getConverter()
      ),
      ativo: snapshot.get("ativo"),
      dias: snapshot.get("dias"),
      horarios: snapshot.get("horarios"),
    });
  }
}

interface IHorario {
  ref: DocumentReference<Horario>;
  linha: DocumentReference<Linha>;
  ativo: boolean;
  dias: DIAS;
  horarios: string[];
}

export enum DIAS {
  Util = "ÚTIL",
  Sabado = "SÁBADO",
  DomingoEFeriado = "DOMINGO_E_FERIADO",
}
