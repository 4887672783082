import { DocumentReference, DocumentSnapshot } from "firebase/firestore";
import AdministradorDb from "../db/administradorDb";

export default class Administrador implements IAdministrador {
  public ref!: DocumentReference<Administrador>;
  public nome!: string;
  public email!: string;

  static fromFirestore(snapshot: DocumentSnapshot): Administrador {
    return new Administrador({
      ref: snapshot.ref.withConverter(new AdministradorDb().getConverter()),
      nome: snapshot.get("nome"),
      email: snapshot.get("email"),
    });
  }

  constructor(param: IAdministrador) {
    Object.assign(this, param);
  }
}

interface IAdministrador {
  ref: DocumentReference<Administrador>;
  nome: string;
  email: string;
}
