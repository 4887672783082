import React, { useEffect, useState } from "react";
import { DirectionsService } from "@react-google-maps/api";
import { chain, first, isEqual, last, map, sumBy } from "lodash";

type Props = {
  calcular: boolean;
  pontos: google.maps.LatLngLiteral[];
  rotaCallback: (rota: google.maps.LatLng[]) => void;
};

function Direcoes({ calcular, pontos, rotaCallback }: Props) {
  const [directionsRequests, setDirectionsRequests] = useState<
    google.maps.DirectionsRequest[]
  >([]);
  const [rotasParciais, setRotasParciais] = useState<google.maps.LatLng[][]>(
    []
  );

  useEffect(() => {
    setDirectionsRequests(
      chain(pontos)
        .chunk(25)
        .map((pontosChunk) => ({
          origin: first(pontosChunk)!,
          destination: last(pontosChunk)!,
          travelMode: google.maps.TravelMode.DRIVING,
          waypoints: map(
            pontosChunk,
            (p) =>
              ({
                location: p,
                stopover: false,
              } as google.maps.DirectionsWaypoint)
          ),
        }))
        .value()
    );
  }, [pontos.length, sumBy(pontos, "lat"), sumBy(pontos, "lng")]);

  useEffect(() => {
    setRotasParciais(map(directionsRequests, (_d, i) => []));
  }, [directionsRequests]);

  useEffect(() => {
    if (rotasParciais.length === directionsRequests.length) {
      rotaCallback(chain(rotasParciais).flatten().value());
    }
  }, [rotasParciais, directionsRequests.length]);

  function callback(
    result: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus,
    index: number
  ) {
    if (
      status === google.maps.DirectionsStatus.OK &&
      result?.routes[0].overview_path
    ) {
      setRotasParciais((prev) =>
        map(prev, (r, i) => (i === index ? result.routes[0].overview_path : r))
      );
    }
  }

  return (
    <>
      {calcular &&
        !!directionsRequests &&
        map(directionsRequests, (directionsRequest, index) => (
          <DirectionsService
            key={index}
            options={directionsRequest}
            callback={(result, status) => callback(result, status, index)}
          />
        ))}
    </>
  );
}

export default React.memo(
  Direcoes,
  (prev, next) =>
    isEqual(prev.pontos, next.pontos) && prev.rotaCallback === next.rotaCallback
);
