import { Box, Paper } from "@mui/material";
import Login from "./login";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";

export default function Auth() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: (theme) =>
          `linear-gradient(120deg,${theme.palette.primary.dark},${theme.palette.secondary.main})`,
        backgroundSize: "200% 200%",
        animation: "gradient-animation 10s ease infinite",
        "@keyframes gradient-animation": {
          "0%": {
            backgroundPosition: "0% 0%",
          },
          "25%": {
            backgroundPosition: "100% 0%",
          },
          "50%": {
            backgroundPosition: "100% 100%",
          },
          "75%": {
            backgroundPosition: "0% 100%",
          },
          "100%": {
            backgroundPosition: "0% 0%",
          },
        },
      }}
    >
      <Container maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center" py={4}>
          <Box py={2}>
            <Box
              borderRadius="50%"
              boxShadow={(theme) => theme.shadows[1]}
              width={128}
              height={128}
            >
              <img alt="logo" src="/logo.png" style={{ maxWidth: 128 }} />
            </Box>
          </Box>
          <Paper sx={{ minWidth: 360, pt: 2 }}>
            <Login onClose={() => navigate("/app/linhas")} />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}
