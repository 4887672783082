import {
  Avatar,
  Box,
  Fab,
  Icon,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  getDoc,
  limit,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { isUndefined, map } from "lodash";
import { useEffect, useState } from "react";
import { Add, Badge, Search } from "@mui/icons-material";
import Passageiro, {
  StatusCredencial,
  statusCredencial,
} from "../../models/passageiro";
import PassageiroDb from "../../db/passageiroDb";
import Residencial from "../../models/residencial";
import avatarColorGenerator from "../../util/avatarColorGenerator";
import { DialogUsuario } from "./dialogUsuario";

export default function Passageiros() {
  const [passageiros, setPassageiros] = useState<Passageiro[]>([]);
  const [editarPassageiro, setEditarPassageiro] = useState<Passageiro>();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const searchConstraints = !!email
      ? [
          where("email", ">=", email),
          where(
            "email",
            "<",
            email.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1))
          ),
        ]
      : [];
    return onSnapshot(
      query(
        new PassageiroDb().collection({
          passageiroId: "",
        }),
        limit(20),
        ...searchConstraints
      ),
      async (queryDocs) => {
        //TODO: para passageiros criados da forma antiga
        await queryDocs.forEach(async (s) => {
          const credencial = s.get("credencial");
          if (isUndefined(credencial)) {
            await updateDoc(s.ref, { credencial: null });
          }
        });
        setPassageiros(map(queryDocs.docs, (doc) => doc.data()));
      }
    );
  }, [email]);

  return (
    <Box>
      <Box pb={2}>
        <TextField
          id="standard-email"
          size="small"
          type="email"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          variant="outlined"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Passageiro</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Residencial</TableCell>
              <TableCell>Quadra</TableCell>
              <TableCell>Lote</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(passageiros, (usuario) => {
              return (
                <RowUsuario
                  usuario={usuario}
                  onClick={(pas) => setEditarPassageiro(pas)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!!editarPassageiro && (
        <DialogUsuario
          key="editar"
          open={!!editarPassageiro}
          onClose={() => setEditarPassageiro(undefined)}
          usuario={editarPassageiro}
        />
      )}
      <Fab
        variant="extended"
        size="large"
        color="success"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => setOpen(true)}
      >
        <Add />
        Adicionar
      </Fab>
      {open && (
        <DialogUsuario key="criar" open={open} onClose={() => setOpen(false)} />
      )}
    </Box>
  );
}

function RowUsuario({
  usuario,
  onClick,
}: {
  usuario: Passageiro;
  onClick: (passageiro: Passageiro) => void;
}) {
  const [residencial, setResidencial] = useState<Residencial>();
  useEffect(() => {
    getDoc(usuario.residencial).then((doc) => setResidencial(doc.data()));
  }, [usuario.residencial]);
  return (
    <TableRow
      key={usuario.ref.id}
      onClick={() => onClick(usuario)}
      hover
      style={{ cursor: "pointer" }}
    >
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{ backgroundColor: avatarColorGenerator(usuario.ref.id) }}
            src={usuario.fotoDePerfil}
          >
            {usuario.nome[0]}
          </Avatar>
          <Box mr={1} />
          {usuario.nome}
        </Box>
      </TableCell>
      <TableCell>{usuario.email}</TableCell>
      <TableCell>{residencial?.nome}</TableCell>
      <TableCell>{usuario.quadra}</TableCell>
      <TableCell>{usuario.lote}</TableCell>
      <TableCell>
        <Tooltip
          title={(function () {
            switch (statusCredencial(usuario.credencial)) {
              case StatusCredencial.EMITIDA:
                return "Emitida";
              case StatusCredencial.NAO_EMITIDA:
                return "Não Emitida";
              case StatusCredencial.CANCELADA:
                return "Cancelada";
              case StatusCredencial.VENCIDA:
                return "Vencida";
            }
          })()}
        >
          <Icon
            color={(function () {
              switch (statusCredencial(usuario.credencial)) {
                case StatusCredencial.EMITIDA:
                  return "success";
                case StatusCredencial.NAO_EMITIDA:
                  return "disabled";
                case StatusCredencial.CANCELADA:
                  return "error";
                case StatusCredencial.VENCIDA:
                  return "warning";
              }
            })()}
          >
            <Badge />
          </Icon>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
