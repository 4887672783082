import { DocumentReference, DocumentSnapshot } from "firebase/firestore";
import TermoDb from "../db/termoDb";

export default class Termo implements ITermo {
  public ref!: DocumentReference<Termo>;
  public texto!: string;
  public data!: Date;

  constructor(param: ITermo) {
    Object.assign(this, param);
  }

  static fromFirestore(snapshot: DocumentSnapshot) {
    return new Termo({
      ref: snapshot.ref.withConverter(new TermoDb().getConverter()),
      texto: snapshot.get("texto"),
      data: snapshot.get("data").toDate(),
    });
  }
}

interface ITermo {
  ref: DocumentReference<Termo>;
  texto: string;
  data: Date;
}
