import { ArrowBack, Done } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Slider,
  Typography,
} from "@mui/material";
import { getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { chain, map, max, min } from "lodash";
import { useEffect, useState } from "react";
import Linha from "../models/linha";
import Corrida from "../models/corrida";
import CorridaDb from "../db/corridaDb";
import Motorista from "../models/motorista";
import Carro from "../models/carro";
import avatarColorGenerator from "../util/avatarColorGenerator";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Map from "../components/map";
import Localizacao from "../models/localizacao";
import LocalizacaoDb from "../db/localizacaoDb";

export default function PaginaCorrida() {
  const { corridaId } = useParams();
  const [corrida, setCorrida] = useState<Corrida>();
  const [motorista, setMotorista] = useState<Motorista>();
  const [carro, setCarro] = useState<Carro>();
  const [linha, setLinha] = useState<Linha>();
  const [localizacoes, setLocalizacoes] = useState<Localizacao[]>([]);
  const [bounds, setBounds] = useState<google.maps.LatLngBoundsLiteral>();
  const [dataMax, setDataMax] = useState<number>(moment().unix());
  const navigate = useNavigate();

  useEffect(() => {
    if (!!corridaId) {
      return onSnapshot(
        new CorridaDb().doc({
          corridaId,
        }),
        (snapshot) => {
          setCorrida(snapshot.data());
        }
      );
    }
  }, [corridaId]);

  useEffect(() => {
    if (!!corrida?.ref) {
      return onSnapshot(
        new LocalizacaoDb().collection({
          corridaId: corrida.ref.id,
          localizacaoId: "",
        }),
        (queryDocs) => setLocalizacoes(map(queryDocs.docs, (doc) => doc.data()))
      );
    }
  }, [corrida?.ref]);

  useEffect(() => {
    if (!!corrida?.motorista)
      getDoc(corrida.motorista).then((motoristaDoc) =>
        setMotorista(motoristaDoc.data())
      );
  }, [corrida?.motorista]);

  useEffect(() => {
    if (!!corrida?.carro)
      getDoc(corrida.carro).then((carroDoc) => setCarro(carroDoc.data()));
  }, [corrida?.carro]);

  useEffect(() => {
    if (!!corrida?.linha)
      getDoc(corrida.linha).then((linhaDoc) => setLinha(linhaDoc.data()));
  }, [corrida?.linha]);

  useEffect(() => {
    if (localizacoes.length > 0)
      setBounds({
        north: max(map(localizacoes, (l) => l.posicao.latitude))!,
        south: min(map(localizacoes, (l) => l.posicao.latitude))!,
        east: max(map(localizacoes, (l) => l.posicao.longitude))!,
        west: min(map(localizacoes, (l) => l.posicao.longitude))!,
      });
  }, [localizacoes]);

  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={2} width="100%" pb={2}>
        <Grid item xs={3}>
          <Paper sx={{ p: 2 }}>
            {!!corrida ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>

                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  py={2}
                >
                  <Avatar
                    sx={{
                      backgroundColor: avatarColorGenerator(
                        motorista?.ref.id ?? ""
                      ),
                    }}
                  >
                    {motorista?.nome[0] ?? ""}
                  </Avatar>
                  <Box mr={1} />
                  {motorista?.nome ?? ""}
                </Box>

                <Typography gutterBottom>
                  {carro?.numero} - {carro?.placa}
                </Typography>

                <Typography gutterBottom>{linha?.nome}</Typography>
                <Box pb={2} />
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color="textSecondary"
                >
                  Partida:
                </Typography>
                <Typography gutterBottom>
                  {moment(corrida.dataInicio).format("dddd, DD/MM HH:mm:ss")}
                </Typography>

                <Typography
                  variant="caption"
                  fontWeight={700}
                  color="textSecondary"
                >
                  Fim:
                </Typography>
                <Typography gutterBottom>
                  {!!corrida.dataFim ? (
                    moment(corrida.dataFim).format("dddd, DD/MM HH:mm:ss")
                  ) : (
                    <Button
                      startIcon={<Done />}
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        updateDoc(corrida.ref, { dataFim: moment().toDate() })
                      }
                    >
                      Finalizar Corrida
                    </Button>
                  )}
                </Typography>

                <Box pt={2} width="100%">
                  <Typography
                    variant="caption"
                    fontWeight={700}
                    color="textSecondary"
                  >
                    Progresso:
                  </Typography>
                  <Slider
                    min={moment(corrida.dataInicio).unix()}
                    max={
                      !!corrida.dataFim
                        ? moment(corrida.dataFim).unix()
                        : moment().unix()
                    }
                    value={dataMax}
                    onChange={(_e, value) => {
                      if (typeof value === "number") setDataMax(value);
                    }}
                  />
                  <Typography gutterBottom>
                    {moment.unix(dataMax).format("HH:mm:ss")}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Paper>
        </Grid>
        <Grid item xs={9} height={"calc(100vh - 64px - 64px)"}>
          <Map
            bounds={bounds}
            pontos={[]}
            rota={chain(localizacoes)
              .orderBy("dataHora")
              .filter((l) =>
                moment(l.dataHora).isSameOrBefore(moment.unix(dataMax))
              )
              .map("posicao")
              .value()}
          ></Map>
        </Grid>
      </Grid>
    </Container>
  );
}
