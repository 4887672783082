import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { CACHE_SIZE_UNLIMITED, initializeFirestore } from "firebase/firestore";
import {
  getAuth,
  indexedDBLocalPersistence,
  setPersistence,
} from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { LoadScript } from "@react-google-maps/api";
import "moment/locale/pt-br";
import moment from "moment";

moment.locale("pt-br");

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});
// enableIndexedDbPersistence(db);
setPersistence(getAuth(), indexedDBLocalPersistence);
getPerformance();
// connectFunctionsEmulator(getFunctions(), "localhost", 5001);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

declare module "@mui/material/styles" {
  interface Palette {
    caribbeanCurrent: Palette["primary"];
    teaRose: Palette["primary"];
    pumpkin: Palette["primary"];
    richBlack: Palette["primary"];
    cambridgeBlue: Palette["primary"];
  }

  interface PaletteOptions {
    caribbeanCurrent: PaletteOptions["primary"];
    teaRose: PaletteOptions["primary"];
    pumpkin: PaletteOptions["primary"];
    richBlack: PaletteOptions["primary"];
    cambridgeBlue: PaletteOptions["primary"];
  }
}

const rawTheme = createTheme();
const theme = createTheme({
  palette: {
    background: {
      default: "#F7F4F1",
      paper: "#FAFEFF",
    },
    primary: {
      main: "#F08034",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#00687A",
      contrastText: "#ffffff",
    },
    caribbeanCurrent: rawTheme.palette.augmentColor({
      color: { main: "#00687A" },
      name: "caribbeanCurrent",
    }),
    teaRose: rawTheme.palette.augmentColor({
      color: { main: "#DAB8B6" },
      name: "teaRose",
    }),
    pumpkin: rawTheme.palette.augmentColor({
      color: { main: "#F08034" },
      name: "pumpkin",
    }),
    richBlack: rawTheme.palette.augmentColor({
      color: { main: "#06080F" },
      name: "richBlack",
    }),
    cambridgeBlue: rawTheme.palette.augmentColor({
      color: { main: "#7C9F9D" },
      name: "cambridgeBlue",
    }),
    success: { main: "#446900", contrastText: "#ffffff" },
    warning: { main: "#785a00", contrastText: "#ffffff" },
    error: { main: "#b6241b", contrastText: "#ffffff" },
    info: { main: "#006496", contrastText: "#ffffff" },
    //https://coolors.co/50903f-f1b861-ff4060-7edaee
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: "'Mulish', sans-serif;",
    fontWeightLight: 400,
    fontWeightRegular: 600,
    fontWeightMedium: 800,
    fontWeightBold: 1000,
  },
});

root.render(
  <LoadScript
    googleMapsApiKey="AIzaSyBCm3rLp8-_P68aUUFzrB4VCE10izlrxBU"
    libraries={["places", "visualization"]}
  >
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main style={{ margin: 0 }}>
        <App />
      </main>
    </ThemeProvider>
  </LoadScript>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
