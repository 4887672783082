import { chain, filter, map } from "lodash";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { Ponto } from "../models/linha";

export enum PontosActionType {
  POPULAR,
  EDITAR,
  ADICIONAR,
  REMOVER,
}

interface PontosAction {
  type: PontosActionType;
  payload: {
    popular?: PontoState[];
    editar?: PontoState;
    adicionar?: PontoState;
    remover?: PontoState;
  };
}

interface PontosState {
  pontos: PontoState[];
}

export interface PontoState extends Ponto {
  destaque: boolean;
  selecionado: boolean;
}

function PontosReducer(
  { pontos }: PontosState,
  { type, payload: { popular, editar, adicionar, remover } }: PontosAction
): PontosState {
  switch (type) {
    case PontosActionType.POPULAR:
      if (!popular) throw new Error("payload inválido");
      return { pontos: popular };
    case PontosActionType.EDITAR:
      if (!editar) throw new Error("payload inválido");
      return {
        pontos: map(pontos, (p) => {
          if (p.id === editar.id) return editar;
          else return p;
        }),
      };
    case PontosActionType.ADICIONAR:
      if (!adicionar) throw new Error("payload inválido");
      return {
        pontos: chain(pontos).concat(adicionar).value(),
      };
    case PontosActionType.REMOVER:
      if (!remover) throw new Error("payload inválido");
      return {
        pontos: filter(pontos, (p) => p !== remover),
      };
    default:
      throw new Error("action sem type");
  }
}

const PontosReducerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, pontoDispatch] = useReducer(PontosReducer, { pontos: [] });
  return (
    <PontosContext.Provider value={state}>
      <PontosDispatchContext.Provider value={pontoDispatch}>
        {children}
      </PontosDispatchContext.Provider>
    </PontosContext.Provider>
  );
};

export default PontosReducerProvider;

const PontosContext = createContext<PontosState>({ pontos: [] });
const PontosDispatchContext = createContext<Dispatch<PontosAction>>((_) => {});

export const usePontos = () => {
  return useContext(PontosContext);
};

export const usePontosDispatch = () => {
  return useContext(PontosDispatchContext);
};
