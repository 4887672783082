import React, { PropsWithChildren, WheelEvent, useRef, useState } from "react";
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { Edit } from "@mui/icons-material";

export default function UploadAvatar({
  disabled = false,
  children,
  onSave,
}: PropsWithChildren<{
  onSave: (canvas: HTMLCanvasElement) => void;
  disabled?: boolean;
}>) {
  const [image, setImage] = useState<File | null>(null);
  const [editor, setEditor] = useState<AvatarEditor | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [scale, setScale] = useState(1);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = !!event.target.files ? event.target.files[0] : null;
    if (!file) return;
    setImage(file);
  };

  const handleSave = () => {
    if (!editor) return;

    const canvas = editor.getImageScaledToCanvas();
    onSave(canvas);
    // canvas.toBlob((blob) => {
    //   if (!!blob)
    //   new File([blob], "fileName.jpg", { type: "image/jpeg" })
    // }, "image/jpeg");
    setImage(null);
  };

  function handleWhell(event: WheelEvent<HTMLDivElement>): void {
    setScale((curr) => {
      const newScale = curr - event.deltaY * 0.001;
      if (newScale < 1) return 1;

      return newScale;
    });
  }

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        accept="image/jpeg"
        style={{ display: "none" }}
        onChange={handleUpload}
        hidden
        disabled={disabled}
      />
      <Badge
        color="primary"
        badgeContent={<Edit fontSize="small" />}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClick={() => inputRef.current?.click()}
        invisible={disabled}
      >
        {children}
      </Badge>
      {!!image && !disabled && (
        <Dialog open={!!image}>
          <DialogContent>
            <div onWheelCapture={handleWhell}>
              <AvatarEditor
                image={image}
                width={200}
                height={200}
                ref={setEditor}
                scale={scale}
                border={50}
                borderRadius={100}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleSave}>
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
