import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  FirestoreDataConverter,
  getFirestore,
} from "firebase/firestore";
import { keys, reduce, replace, trimEnd } from "lodash";

export default abstract class BaseDb<
  TModel,
  TPath = { [key: string]: string }
> {
  abstract _path: string;
  public collection(pathValues: TPath): CollectionReference<TModel> {
    const completePath = reduce(
      keys(pathValues),
      (prev, current) => replace(prev, current, (pathValues as any)[current]),
      this._path
    );
    return collection(getFirestore(), trimEnd(completePath, "/")).withConverter(
      this.getConverter()
    );
  }
  public doc(pathValues: TPath): DocumentReference<TModel> {
    const completePath = reduce(
      keys(pathValues),
      (prev, current) => replace(prev, current, (pathValues as any)[current]),
      this._path
    );
    return doc(getFirestore(), completePath).withConverter(this.getConverter());
  }
  abstract getConverter(): FirestoreDataConverter<TModel>;
}
