import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  getDoc,
  limit,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { map } from "lodash";
import { useEffect, useState } from "react";
import { Check } from "@mui/icons-material";
import Passageiro, { StatusCredencial } from "../../models/passageiro";
import PassageiroDb from "../../db/passageiroDb";
import avatarColorGenerator from "../../util/avatarColorGenerator";
import moment from "moment";
import { useAuth } from "../auth/authProvider";
import AdministradorDb from "../../db/administradorDb";
import Administrador from "../../models/administrador";
import { DialogUsuario } from "./dialogUsuario";

export default function Canceladas() {
  const [passageiros, setPassageiros] = useState<Passageiro[]>([]);
  const [editarPassageiro, setEditarPassageiro] = useState<Passageiro>();

  useEffect(() => {
    return onSnapshot(
      query(
        new PassageiroDb().collection({
          passageiroId: "",
        }),
        limit(20),
        where("credencial.status", "==", StatusCredencial.CANCELADA)
      ),
      (queryDocs) => {
        setPassageiros(map(queryDocs.docs, (doc) => doc.data()));
      }
    );
  }, []);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Passageiro</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Analisado Em</TableCell>
              <TableCell>Analisado Por</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(passageiros, (usuario) => {
              return (
                <RowUsuario
                  usuario={usuario}
                  onClick={(pas) => setEditarPassageiro(pas)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!!editarPassageiro && (
        <DialogUsuario
          key="editar"
          open={!!editarPassageiro}
          onClose={() => setEditarPassageiro(undefined)}
          usuario={editarPassageiro}
        />
      )}
    </Box>
  );
}

function RowUsuario({
  usuario,
  onClick,
}: {
  usuario: Passageiro;
  onClick: (passageiro: Passageiro) => void;
}) {
  const auth = useAuth();
  const [administrador, setAdministrador] = useState<Administrador>();
  useEffect(() => {
    getDoc(usuario.credencial!.emitidaPor!).then((doc) =>
      setAdministrador(doc.data())
    );
  }, [usuario.credencial]);

  return (
    <TableRow
      key={usuario.ref.id}
      onClick={() => onClick(usuario)}
      hover
      style={{ cursor: "pointer" }}
    >
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{ backgroundColor: avatarColorGenerator(usuario.ref.id) }}
            src={usuario.fotoDePerfil}
          >
            {usuario.nome[0]}
          </Avatar>
          <Box mr={1} />
          {usuario.nome}
        </Box>
      </TableCell>
      <TableCell>{usuario.email}</TableCell>
      <TableCell>
        {moment(usuario.credencial?.emitidaEm).format("DD/MM/YYYY")}
      </TableCell>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{
              backgroundColor: avatarColorGenerator(
                administrador?.ref?.id || ""
              ),
            }}
          >
            {administrador?.nome[0]}
          </Avatar>
          <Box mr={1} />
          {administrador?.nome}
        </Box>
      </TableCell>
      <TableCell align="right" sx={{ minWidth: 120 }}>
        <IconButton
          color="success"
          onClick={() =>
            setDoc(
              usuario.ref,
              new Passageiro({
                ...usuario,
                credencial: {
                  ...usuario.credencial!,
                  status: StatusCredencial.EMITIDA,
                  emitidaEm: moment().toDate(),
                  emitidaPor: new AdministradorDb().doc({
                    administradorId: auth.usuario!.uid,
                  }),
                  validade: moment().add(2, "year").endOf("month").toDate(),
                },
              })
            )
          }
        >
          <Tooltip title="Reemitir">
            <Check />
          </Tooltip>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
