import { Add, ScheduleSend, Send } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { addDoc, onSnapshot, query } from "firebase/firestore";
import { map, orderBy } from "lodash";
import { useEffect, useState } from "react";
import Alerta from "../models/alerta";
import AlertaDb from "../db/alertaDb";
import moment from "moment";

export default function PaginaAlertas() {
  const [alertas, setAlertas] = useState<Alerta[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return onSnapshot(
      query(
        new AlertaDb().collection({
          alertaId: "",
        })
      ),
      (queryDocs) => {
        setAlertas(
          orderBy(
            map(queryDocs.docs, (doc) => doc.data()),
            "dataHora",
            "desc"
          )
        );
      }
    );
  }, []);

  return (
    <Container maxWidth="lg">
      <DialogaAlerta open={open} onClose={() => setOpen(false)} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Envio</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Mensagem</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(alertas, (alerta) => {
              return <RowAlerta key={alerta.ref.id} alerta={alerta} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        variant="extended"
        size="large"
        color="success"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => setOpen(true)}
      >
        <Add />
        Adicionar
      </Fab>
    </Container>
  );
}

function RowAlerta({ alerta }: { alerta: Alerta }) {
  return (
    <TableRow key={alerta.ref.id}>
      <TableCell>
        {alerta.enviado ? (
          <Send color="success" />
        ) : (
          <ScheduleSend color="info" />
        )}
      </TableCell>
      <TableCell>
        {moment(alerta.dataHora).format("DD/MM/yy HH:mm:ss")}
      </TableCell>
      <TableCell>{alerta.titulo}</TableCell>
      <TableCell>{alerta.mensagem}</TableCell>
    </TableRow>
  );
}

function DialogaAlerta({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [titulo, setTitulo] = useState("");
  const [mensagem, setMensagem] = useState("");
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <FormGroup>
          <Box display="flex" flexDirection="column">
            <TextField
              fullWidth
              label="Título"
              value={titulo}
              onChange={(e) => setTitulo(e.target.value)}
            />
            <Box pt={1} />
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Mensagem"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
            />
            <Box pt={1} />
          </Box>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="text" color={"inherit"} onClick={onClose}>
          {"Cancelar"}
        </Button>

        <Button
          size="large"
          variant="contained"
          color={"success"}
          disabled={!!!titulo || !!!mensagem}
          onClick={() =>
            addDoc(
              new AlertaDb().collection({ alertaId: "" }),
              new Alerta({
                ref: new AlertaDb().doc({ alertaId: "-" }),
                titulo,
                mensagem,
                dataHora: moment().toDate(),
                enviado: false,
              })
            ).then(onClose)
          }
        >
          {"Enviar Alerta"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
