import { isNull, isUndefined, omit } from "lodash";

export default function toFirestore<T extends object>(toBeSaved: T) {
  return toFirestoreInternal(omit(toBeSaved, ["ref"]));
}
function toFirestoreInternal<T>(toBeSaved: T) {
  const objT: Pick<T, keyof T> = { ...toBeSaved };
  let objDb = objT as any;
  Object.keys(objDb).forEach((key) => {
    if (isUndefined(objDb[key])) delete objDb[key];
    else return;
  });

  return objDb;
}
