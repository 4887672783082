import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DocumentReference, getDocs } from "firebase/firestore";
import { find, map } from "lodash";
import { useEffect, useState } from "react";
import ResidencialDb from "../../db/residencialDb";
import Residencial from "../../models/residencial";

type Props = {
  value?: DocumentReference<Residencial>;
  onSelect: (residencial?: DocumentReference<Residencial>) => void;
};

export default function ResidencialSelect({ value, onSelect }: Props) {
  const [residenciais, setResidenciais] = useState<Residencial[]>([]);
  const [residencialSelecionado, setResidencialSelecionado] = useState<
    DocumentReference<Residencial> | undefined
  >(value);

  useEffect(() => {
    getDocs(
      new ResidencialDb().collection({
        residencialId: "",
      })
    ).then((query) => setResidenciais(map(query.docs, (doc) => doc.data())));
  }, []);

  useEffect(() => {
    onSelect(residencialSelecionado);
  }, [onSelect, residencialSelecionado]);

  return (
    <>
      <FormControl>
        <InputLabel id="residencial-select-label">Residencial</InputLabel>
        <Select
          label="residencial"
          id="residencial-select"
          labelId="residencial-select-label"
          variant="outlined"
          sx={{ minWidth: "24ch" }}
          value={residencialSelecionado?.id}
          onChange={(e) =>
            setResidencialSelecionado(
              find(residenciais, (c) => c.ref.id === e.target.value)!.ref
            )
          }
        >
          {map(residenciais, (residencial) => (
            <MenuItem key={residencial.ref.id} value={residencial.ref.id}>
              {residencial.nome}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
