import { useState } from "react";
import { Button, Container, TextField, Link, Box } from "@mui/material";
import { isEmpty } from "lodash";
import { Alert } from "@mui/material";
import {
  getAuth,
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithEmailLink,
} from "firebase/auth";
import { Perfil } from "../../models/enuns";

type Props = {
  onClose: () => void;
};

export default function Login({ onClose }: Props) {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [erros, setErros] = useState<{
    email: boolean;
    erroEmail: string;
    senha: boolean;
    erroSenha: string;
  }>({
    email: false,
    erroEmail: "",
    senha: false,
    erroSenha: "",
  });
  const [mensagem, setMensagem] = useState("");
  const [isMagicLink, setIsMagicLink] = useState(
    isSignInWithEmailLink(getAuth(), window.location.href)
  );
  const signInWithMagicLink = (event: any) => {
    event.preventDefault();
    if (!isEmpty(email)) {
      signInWithEmailLink(getAuth(), email)
        .then(onClose)
        .catch((error) => {
          setErros((prev) => ({ ...prev, senha: true }));
          setIsMagicLink(false);
        });
    }
  };
  const signInWithPassword = (event: any) => {
    event.preventDefault();
    const camposComErro = camposObrigatoriosVazios();

    if (isEmpty(camposComErro)) {
      signInWithEmailAndPassword(getAuth(), email, senha)
        .then((userAuth) =>
          userAuth.user.getIdTokenResult().then((tokenResult) => {
            if (tokenResult.claims.perfil === Perfil.ADMINISTRADOR) {
              return;
            } else {
              throw new Error("Usuário não permitido");
            }
          })
        )
        .then(onClose)
        .catch((error) => {
          switch (error.code) {
            case "auth/user-not-found":
              setErros((prev) => ({
                ...prev,
                email: true,
                erroEmail: "Usuário não existe",
              }));
              break;
            case "auth/wrong-password":
              setErros((prev) => ({
                ...prev,
                senha: true,
                erroSenha: "Senha incorreta",
              }));
              break;
            default:
              setErros((prev) => ({
                ...prev,
                email: true,
                erroEmail: error.message,
              }));
              break;
          }
        });
    }
    setErros((prev) => ({ ...prev, ...camposComErro }));
  };

  const camposObrigatoriosVazios = () => {
    let camposComErro = {};
    if (!email) camposComErro = { ...camposComErro, email: true };
    if (!senha)
      camposComErro = {
        ...camposComErro,
        senha: true,
      };
    return camposComErro;
  };

  const resetarSenha = () => {
    if (!email) {
      setErros((prev) => ({ ...prev, email: true }));
    } else {
      sendPasswordResetEmail(getAuth(), email).then(() =>
        setMensagem(
          "Um e-mail com um link para a recuperação de senha foi enviado para você."
        )
      );
    }
  };

  return (
    <form onSubmit={isMagicLink ? signInWithMagicLink : signInWithPassword}>
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: 320,
        }}
      >
        {mensagem && <Alert severity="success">{mensagem}</Alert>}
        <TextField
          variant="outlined"
          margin="normal"
          error={erros.email}
          helperText={erros.erroEmail}
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {isMagicLink ? (
          <></>
        ) : (
          <TextField
            variant="outlined"
            margin="normal"
            error={erros.senha}
            helperText={erros.erroSenha}
            fullWidth
            name="senha"
            label="Senha"
            type="password"
            id="senha"
            autoComplete="current-senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          sx={{ marginX: 0, marginTop: 3, marginBottom: 1 }}
        >
          Entrar
        </Button>
        {/* <Button
          fullWidth
          variant="outlined"
          size="large"
          color="inherit"
          sx={{ marginX: 0, marginTop: 0, marginBottom: 1 }}
          onClick={signInWithGoogle}
          startIcon={<Google strokeWidth={3} />}
        >
          Entrar com o Google
        </Button> */}
        <Box paddingY={1} />
        <Link onClick={resetarSenha}>Esqueceu sua senha?</Link>
      </Container>
    </form>
  );
}
