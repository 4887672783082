import {
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import AlertaDb from "../db/alertaDb";

export default class Alerta implements IAlerta {
  public ref!: DocumentReference<Alerta>;
  public titulo!: string;
  public mensagem!: string;
  public dataHora!: Date;
  public enviado!: boolean;

  constructor(param: IAlerta) {
    Object.assign(this, param);
  }

  static fromFirestore(snapshot: DocumentSnapshot): Alerta {
    return new Alerta({
      ref: snapshot.ref.withConverter(new AlertaDb().getConverter()),
      titulo: snapshot.get("titulo"),
      mensagem: snapshot.get("mensagem"),
      dataHora: (snapshot.get("dataHora") as Timestamp).toDate(),
      enviado: snapshot.get("enviado"),
    });
  }
}

interface IAlerta {
  ref: DocumentReference<Alerta>;
  titulo: string;
  mensagem: string;
  dataHora: Date;
  enviado: boolean;
}
