import { useTheme } from "@mui/material";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import { GeoPoint } from "firebase/firestore";
import { map } from "lodash";
import { PropsWithChildren, useEffect, useState } from "react";
import { PontoState } from "../../reducers/pontosReducerProvider";
import styles from "./styles";

export default function Map({
  pontos,
  rota,
  bounds,
  children,
}: PropsWithChildren<{
  pontos: PontoMap[];
  rota: GeoPoint[];
  bounds?: google.maps.LatLngBoundsLiteral;
}>) {
  const theme = useTheme();
  const path = map(rota, geoPointToLatLng);
  const [mapa, setMapa] = useState<google.maps.Map>();
  const [centro, setCentro] = useState<google.maps.LatLngLiteral>();

  useEffect(() => {
    navigator?.geolocation.getCurrentPosition((position) => {
      setCentro({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    if (mapa && bounds) mapa.fitBounds(bounds);
  }, [mapa, bounds]);

  return (
    <GoogleMap
      options={{
        styles: styles.light,
      }}
      mapContainerStyle={{
        height: "100%",
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.palette.action.disabledBackground,
      }}
      zoom={14}
      onLoad={setMapa}
      center={centro}
    >
      <Polyline
        path={path}
        options={{
          strokeWeight: 6,
          path: path,
          visible: true,
          strokeColor: theme.palette.primary.main,
        }}
      />
      {map(pontos, (ponto) => (
        <Marker
          key={ponto.id}
          position={{
            lat: ponto.localizacao.latitude,
            lng: ponto.localizacao.longitude,
          }}
          draggable={ponto.selecionado}
          onDragEnd={(e) => e.latLng && ponto.onChangeLatLng(e.latLng)}
          onClick={(e) => ponto.onClick()}
          icon={
            ponto.destaque
              ? "https://fonts.gstatic.com/s/i/materialicons/location_on/v15/24px.svg"
              : "https://fonts.gstatic.com/s/i/materialiconsoutlined/location_on/v15/24px.svg"
          }
        />
      ))}
      {children}
    </GoogleMap>
  );
}

export type PontoMap = {
  onChangeLatLng: (latLng: google.maps.LatLng) => void;
  onClick: () => void;
} & PontoState;

export function geoPointToLatLng(
  geopoint: GeoPoint
): google.maps.LatLngLiteral {
  return {
    lat: geopoint.latitude,
    lng: geopoint.longitude,
  };
}

export function latLngToGeoPoint(latLng: google.maps.LatLng): GeoPoint {
  return new GeoPoint(latLng.lat(), latLng.lng());
}
