import { FirestoreDataConverter } from "firebase/firestore";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";
import Corrida from "../models/corrida";

export default class CorridaDb extends BaseDb<
  Corrida,
  {
    corridaId: string;
  }
> {
  _path: string = "corridas/corridaId";
  getConverter(): FirestoreDataConverter<Corrida> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Corrida.fromFirestore,
    };
  }
}
