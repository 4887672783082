import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { addDoc, onSnapshot, orderBy, query } from "firebase/firestore";
import { map } from "lodash";
import { useEffect, useState } from "react";
import { Add, Visibility } from "@mui/icons-material";
import Termo from "../models/termo";
import TermoDb from "../db/termoDb";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function PaginaTermos() {
  const [termos, setTermos] = useState<Termo[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return onSnapshot(
      query(
        new TermoDb().collection({
          termoId: "",
        }),
        orderBy("data", "desc")
      ),
      (queryDocs) => {
        setTermos(map(queryDocs.docs, (doc) => doc.data()));
      }
    );
  }, []);

  return (
    <Container maxWidth="lg">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(termos, (termo) => {
              return <RowTermo key={termo.ref.id} termo={termo} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        variant="extended"
        size="large"
        color="success"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => setOpen(true)}
      >
        <Add />
        Adicionar
      </Fab>
      <DialogTermo open={open} onClose={() => setOpen(false)} />
    </Container>
  );
}

function RowTermo({ termo }: { termo: Termo }) {
  const [open, setOpen] = useState(false);
  return (
    <TableRow key={termo.ref.id}>
      <TableCell>{termo.data.toLocaleString("pt-BR")}</TableCell>
      <TableCell align="right">
        <DialogTermo termo={termo} open={open} onClose={() => setOpen(false)} />
        <IconButton color="info" onClick={() => setOpen(true)}>
          <Visibility />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function DialogTermo({
  open,
  onClose,
  termo,
}: {
  open: boolean;
  onClose: () => void;
  termo?: Termo;
}) {
  const [texto, setTexto] = useState(termo?.texto ?? "");

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogContent>
        <Box
          sx={{
            "& .ql-container": {
              height: "auto",
            },
            "& .ql-editor": {
              height: 480,
              overflowY: "scroll",
              fontFamily: "Mulish",
              textAlign: "justify",
            },
          }}
        >
          <ReactQuill
            theme="snow"
            modules={modules}
            formats={formats}
            readOnly={!!termo}
            value={texto}
            onChange={(e) => setTexto(e)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button size="large" variant="text" color={"inherit"} onClick={onClose}>
          {"Cancelar"}
        </Button>
        {!!!termo && (
          <Button
            size="large"
            variant="contained"
            color={"success"}
            disabled={!!!texto}
            onClick={() =>
              addDoc(
                new TermoDb().collection({ termoId: "" }),
                new Termo({
                  ref: new TermoDb().doc({ termoId: "-" }),
                  texto,
                  data: new Date(),
                })
              ).then(onClose)
            }
          >
            {"Adicionar"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
