import { FirestoreDataConverter } from "firebase/firestore";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";
import Motorista from "../models/motorista";

export default class MotoristaDb extends BaseDb<
  Motorista,
  {
    motoristaId: string;
  }
> {
  _path: string = "motoristas/motoristaId";
  getConverter(): FirestoreDataConverter<Motorista> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Motorista.fromFirestore,
    };
  }
}
