import { FirestoreDataConverter } from "firebase/firestore";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";
import Administrador from "../models/administrador";

export default class AdministradorDb extends BaseDb<
  Administrador,
  {
    administradorId: string;
  }
> {
  _path: string = "administradores/administradorId";
  getConverter(): FirestoreDataConverter<Administrador> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Administrador.fromFirestore,
    };
  }
}
